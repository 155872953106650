const cssProperties = () => {
  return {
    subtitle: {
      fontWeight: 900,
    },
    inputs: {
      background: "white",
      borderColor: "external light orange !important",
      width: 280,
    },
    line: {
      textDecoration: "underline",
      fontSize: 13,
    },
    btn: {
      fontWeight: 900,
      textTransform: "capitalize",
    },
    btn2: {
      width: 136,
      backgroundColor: "#D0D0D0",
      border: "none",
      outline: "none",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 5,
      fontWeight: 900,
      cursor: "pointer",
    },
    notExistsError: {
      color: "red",
      fontSize: 11,
      fontWeight: 500,
      textAlign: "center",
    },
    loginError: {
      color: "red",
      fontSize: 11,
      fontWeight: 500,
      textAlign: "center",
    },
  };
};


module.exports = { cssProperties };
